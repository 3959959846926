<template>
  <div :id="chartName" class="chart-wrapper" :style="esize"></div>
</template>
<script>
import {chartText} from "@/libs/mixins/chartSubText.js";
import { getExcel } from "@/libs/mixins/GetExcel.js";
function rnd(n, m) {
  var random = Math.floor(Math.random() * (m - n + 1) + n);
  return random;
}

let BarColor = [
  "#c23531",
  "#2f4554",
  "#61a0a8",
  "#d48265",
  "#91c7ae",
  "#749f83",
  "#ca8622",
  "#bda29a",
  "#6e7074",
  "#546570",
  "#c4ccd3"
];

export default {
  name: "majorSystem",
  data() {
    return {
      chartName: this.$options.name,
      title: "各专业接口风险点数量统计图",
      chartData: [],
      chColor: "#c23531"
    };
  },
  props: {
    choosedOptions: {
      type: Object,
      default() {
        return {
          selectedList: [],
          keyword: ""
        };
      }
    },
    esize: {
      type: Object,
      default() {
        return { height: "700px", width: "1000px" };
      }
    },
    drawAgain: {
      type: Number,
      default: 1
    }
  },
  mixins: [chartText,getExcel],

  mounted() {
    let n = rnd(0, BarColor.length - 1);
    this.chColor = BarColor[n];
  },
  watch: {
    drawAgain: {
      immediate: true,
      handler(newVal) {
        if (newVal > 0) {
          this.get_data();
        }
      }
    }
  },
  computed: {
    ctdata() {
      let temp = this.chartData;
      temp.sort((a, b) => {
        return a.value - b.value;
      });
      return temp;
    },
    pureData:function(){
      let res = [];
      let tmp = [];
      for(let ele of this.chartData){
        tmp = [];
        tmp[0] = ele['name'];
        tmp[1] = ele['value'];
        res.push(tmp);
      }
      res.sort((a, b) => {
        return b[1] - a[1];
      });
      return res;
    }
  },
  methods: {
    get_data() {
      let userToken = localStorage.getItem("current_user_token");
      this.$axios
        .post(this.$url + "/chart", {
          chartName: this.chartName,
          choosedOptions: this.choosedOptions,
          userToken: userToken
        })
        .then(response => ((this.chartData = response.data), this.drawIt()));
    },

    drawIt() {
      let vm = this;
      let myChart = this.$echarts.init(document.getElementById(vm.chartName));
      // 绘制图表
      myChart.setOption({
        backgroundColor: "#f7f7f7",

        title: {
          text: vm.title,
          subtext: vm.selectedListTextFormat(vm.choosedOptions.selectedList),
          left: "center",
          top: "12%",
          textStyle: {
            color: "#888",
            verticalAlign: "top"
          }
        },
        tooltip: {
          trigger: "item"
        },
        grid: {
          x: "25%",
          y: "25%",
          x2: "25%",
          y2: "25%",
          borderWidth: 10
        },

        toolbox: {
          show: true,
          orient: "vertical",
          left: "right",
          top: "center",
          feature: {
            myToolsExcel:{
              paddingTop:'5px',
              show:true,
              top:'20px',
              title: '保存数据到EXCEL',
              icon:'image:///img/excel.png',
              onclick:function(){
                vm.excelGo(vm.pureData,['专业','数量']);
                }
            },
            mark: {
              show: true
            },
            dataView: {
              show: true,
              readOnly: false
            },
            restore: {
              show: true
            },
            saveAsImage: {
              show: true
            }
          }
        },
        dataset: {
          source: vm.ctdata
        },
        xAxis: {},
        yAxis: {
          type: "category",
          axisTick: {
            alignWithLabel: true
          },
          axisLabel: {
            rotate: 30
          }
        },
        series: [
          {
            name: "数量",
            type: "bar",
            barWidth: "40%",
            encode: {
              x: 1,
              y: 2
            },
            label: {
              show: true,
              rotate: 15
            },
            itemStyle: {
              color: vm.chColor,
              opacity: 0.8,
              shadowBlur: 1,
              shadowColor: "#e6e6e6",
              shadowOffsetX: 2,
              shadowOffsetY: 2
            }
          }
        ],
        label: {
          normal: {
            textStyle: {
              color: "rgba(255, 255, 255, 0.3)"
            }
          }
        },
        labelLine: {
          normal: {
            lineStyle: {
              color: "rgba(255, 255, 255, 0.3)"
            },
            smooth: 0.2,
            length: 10,
            length2: 20
          }
        }
      });
      /**myChart.on('click', function (value) {
                    vm.$emit("clickToChange", { 'value': [value.name], 'key': vm.chartKey });

                });**/
      myChart.on("legendselectchanged", function(value) {
        vm.$emit("clickToAppend", {
          value: value.selected,
          key: vm.chartName
        });
      });
    }
  }
};
</script>
<style scoped>
.chart-wrapper {
  border: 1px solid #f9f9f9;
  border-radius: 3px;
  margin: 0 auto;
}
</style>